/* eslint-disable no-lonely-if */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { v4 } from 'uuid'

// Components
import Select from 'react-select'
import Content from 'components/shared/Content'
import Loading from 'components/shared/Loading'
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from 'components/shared/CustomLink'

// Images
import ArrowButton from 'img/icon/arrow_button.inline.svg'

// Third Party
import { motion } from 'framer-motion'
import Checkbox from 'react-checkbox-component'

const StyledForm = styled.form`
  position: relative;

  &.whiteLabel {
    label {
      color: ${(props) => props.theme.color.text.light};
      padding-bottom: 0;

      & span {
        color: ${(props) => props.theme.color.text.light};
      }
    }
  }
`

const ConfirmationMessage = styled(Content)`
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.xl};

  padding-left: 30px;
  text-align: center;

  ${(props) =>
    props.newsletter
      ? css`
          color: ${props.theme.color.text.light};
        `
      : css`
          color: ${props.theme.color.text.secondary};
        `}
`

const Label = styled.label`
  display: block;
  position: relative;
`

const Mandatory = styled.span`
  position: absolute;
  top: -6px;
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.face.contrast};
  margin-left: 5px;
`

const GravityForm = ({
  enableConsent,
  newsletter,
  brochure,
  className,
  id,
  split = true,
  defaultValues = {},
}) => {
  const {
    allGfForm: { edges: gravityData },
  } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }) => node.formId === id
  )[0]

  const [consent, setConsent] = useState({
    enabled: enableConsent,
    value: false,
  })

  const [fields, setFields] = useState(defaultValues)
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item) => item.type === 'submit').length === 0
    ) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }
    //if(validateEmail(fields.email))

    setStatus('processing')

    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      if (event.name === 'input_6') {
        if (event.value === true || event.value === '1') {
          setFields({
            ...fields,
            [event.name]: '1',
          })
          setConsent({
            ...consent,
            value: true,
          })
        } else {
          setFields({
            ...fields,
            [event.name]: '0',
          })
          setConsent({
            ...consent,
            value: false,
          })
        }
      } else {
        setFields({
          ...fields,
          [event.name]: event.value,
        })
      }
    }
  }

  if (status === 'done') {
    return <ConfirmationMessage newsletter={newsletter} content={message} />
  }

  if (form.formFields) {
    return (
      <StyledForm
        id={`form_${gfForm.formId}`}
        className={className}
        method="post"
        onSubmit={handleOnSubmit}
      >
        <div className="row">
          <div className="col-12">{status === 'processing' && <Loading />}</div>
          {!newsletter && !brochure ? (
            <>
              <div className={`px-lg-4 col-lg-${split ? 6 : 12}`}>
                {form.formFields &&
                  form.formFields.map((field, key) => {
                    if (field.visibility === 'hidden') {
                      return null
                    }

                    if (Array.isArray(field)) {
                      return (
                        <div key={key}>
                          {field.map((item, index) => (
                            <FormField
                              newsletter={newsletter}
                              key={index}
                              field={item}
                              fields={fields}
                              onChange={handleFieldChange}
                            />
                          ))}
                        </div>
                      )
                    }

                    if (
                      field.type !== 'textarea' &&
                      field.type !== 'submit' &&
                      field.type !== 'consent'
                    ) {
                      return (
                        <div key={key}>
                          <FormField
                            newsletter={newsletter}
                            consent={consent}
                            field={field}
                            fields={fields}
                            onChange={handleFieldChange}
                          />
                        </div>
                      )
                    }

                    return true
                  })}
              </div>
              <div className={`px-lg-4 col-lg-${split ? 6 : 12}`}>
                {form.formFields &&
                  form.formFields.map((field, key) => {
                    if (Array.isArray(field)) {
                      return (
                        <div key={key}>
                          {field.map((item, index) => (
                            <FormField
                              newsletter={newsletter}
                              key={index}
                              field={item}
                              fields={fields}
                              onChange={handleFieldChange}
                            />
                          ))}
                        </div>
                      )
                    }

                    if (
                      field.type === 'textarea' ||
                      field.type === 'submit' ||
                      field.type === 'consent'
                    ) {
                      return (
                        <div key={key}>
                          <FormField
                            newsletter={newsletter}
                            consent={consent}
                            field={field}
                            fields={fields}
                            onChange={handleFieldChange}
                          />
                        </div>
                      )
                    }
                    return true
                  })}
              </div>
            </>
          ) : !brochure ? (
            <div className="col-lg-12">
              {form.formFields &&
                form.formFields.map((field, key) => {
                  if (Array.isArray(field)) {
                    return (
                      <div key={key}>
                        {field.map((item, index) => (
                          <FormField
                            newsletter={newsletter}
                            key={index}
                            field={item}
                            fields={fields}
                            onChange={handleFieldChange}
                          />
                        ))}
                      </div>
                    )
                  }

                  return (
                    <div key={key}>
                      <FormField
                        newsletter={newsletter}
                        consent={consent}
                        field={field}
                        fields={fields}
                        onChange={handleFieldChange}
                      />
                    </div>
                  )
                })}
            </div>
          ) : (
            <div className="row">
              {form.formFields &&
                form.formFields.map((field, key) => {
                  if (Array.isArray(field)) {
                    return (
                      <div key={key} className="testx">
                        {field.map((item, index) => (
                          <FormField
                            newsletter={newsletter}
                            key={index}
                            field={item}
                            fields={fields}
                            onChange={handleFieldChange}
                          />
                        ))}
                      </div>
                    )
                  }

                  return (
                    <div key={key} className="col-12">
                      <FormField
                        newsletter={newsletter}
                        consent={consent}
                        field={field}
                        fields={fields}
                        onChange={handleFieldChange}
                      />
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </StyledForm>
    )
  }

  console.error('No gravity forms found with id', id)
  return false
}

const StyledTextField = styled.div`
  ${Label} {
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: ${(props) => props.theme.font.size.sm};
  }

  input {
    border-radius: 19px;
    border: 1px solid ${(props) => props.theme.color.face.lightBorder};
    width: 100%;
    height: 40px;
    padding: 0 20px 0 20px;
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const TextField = ({
  value,
  onChange,
  field: {
    id,
    type,
    label,
    labelPlacement,
    placeholder,
    whiteLabels,
    isRequired,
    cssClass,
  },
}) => (
  <StyledTextField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextField>
)

const StyledArrowButton = styled(ArrowButton)`
  height: 20px;
  width: 20px;
`

const IconContainer = styled(motion.button)`
  position: absolute;
  right: 52px;
  top: 4px;
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;

  svg {
    height: 100%;
    width: 100%;
  }
`

const StyledEmailField = styled.div`
  ${(props) =>
    props.newsletter
      ? css`
          margin-bottom: 0;
          padding-left: 25px;
          height: 38px;
          width: 321px;

          input {
            height: 100%;
            width: 100%;
            border-radius: 19px;
            border: none;
            padding-left: 20px;
          }
        `
      : css`
          ${Label} {
            padding-left: 20px;
            padding-bottom: 10px;
            font-size: ${props.theme.font.size.sm};
          }

          input {
            border-radius: 19px;
            border: 1px solid ${props.theme.color.face.lightBorder};
            width: 100%;
            height: 40px;
            padding: 0 20px 0 20px;
            font-size: ${props.theme.font.size.sm};
          }
        `}
`

const EmailField = ({
  newsletter,
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <StyledEmailField newsletter={newsletter} className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id} ${type}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
    {newsletter && (
      <IconContainer
        type="submit"
        whileHover={{ rotate: [0, -20, 20, 0] }}
        transition={{ duration: 0.3, type: 'spring' }}
      >
        <StyledArrowButton />
      </IconContainer>
    )}
  </StyledEmailField>
)

const StyledPhoneField = styled.div`
  ${Label} {
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: ${(props) => props.theme.font.size.sm};
  }
  input {
    border-radius: 19px;
    border: 1px solid ${(props) => props.theme.color.face.lightBorder};
    width: 100%;
    height: 40px;
    padding: 0 20px 0 20px;
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <StyledPhoneField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledPhoneField>
)

const StyledTextareaField = styled.div`
  ${Label} {
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: ${(props) => props.theme.font.size.sm};
  }

  textarea {
    border-radius: 19px;
    border: 1px solid ${(props) => props.theme.color.face.lightBorder};
    width: 100%;
    height: 40px;
    padding: 20px 20px 20px 20px;
    font-size: ${(props) => props.theme.font.size.sm};
    min-height: 170px;
  }
`

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}) => (
  <StyledTextareaField className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextareaField>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, cssClass, choices },
}) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div`
  margin-bottom: 20px;

  & .react-select__control {
    border-radius: 19px;
    height: 40px;
    padding-left: 10px;
  }
`

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach((choice) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const StyledSubmitButton = styled.div`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`

const SubmitButton = ({ consent, newsletter, field }) => (
  <StyledSubmitButton
    disabled={consent.enabled ? !consent.value : false}
    className={`${newsletter ? `d-none` : `d-flex justify-content-end`}`}
  >
    <ButtonDefault icon="arrow" isCustom>
      <button type="submit">{field.text}</button>
    </ButtonDefault>
  </StyledSubmitButton>
)

const ButtonSpan = styled.span`
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(114, 57, 149, 1) 0%,
    rgba(11, 189, 209, 1) 100%
  );
  border-radius: 29.5px;
  display: inline-block;
  height: 45px;
`

const UploadButton = styled.button`
  color: #ffffff !important;
  font-weight: 800;
  padding: 10px 50px;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  white-space: nowrap;
`

const FormUpload = styled.div`
  margin-bottom: 30px;
  cursor: pointer;

  label {
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 14px 20px 14px 20px;
    color: ${(props) => props.theme.color.text.secondary};
    font-size: ${(props) => props.theme.font.size.sm};
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #fff;
    font-weight: 800;
    background: linear-gradient(
      90deg,
      rgba(114, 57, 149, 1) 0%,
      rgba(11, 189, 209, 1) 100%
    );
  }

  input {
    //visibility: hidden;
    position: absolute;
    height: 1px;
    opacity: 0;
    outline: none;
    top: 0;
    z-index: -1;
  }
`

const FileField = ({
  value,
  onChange,
  field: {
    id,
    type,
    label,
    allowedExtensions,
    labelPlacement,
    cssClass,
    placeholder,
  },
}) => {
  function handleChange(file) {
    onChange({
      target: {
        name: `input_${id}`,
        value: file,
      },
    })
  }

  function handleFileUpload(e) {
    const {
      e: {
        target: { files },
      },
    } = e

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile())
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i])
      }
    }
  }

  return (
    <FormUpload
      className="form-upload"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Label htmlFor={`input_${id}`}>
        <div className="d-flex align-items-center">{label}</div>
      </Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </FormUpload>
  )
}

const StyledCheckboxField = styled.div`
  .checkbox {
    border-radius: 0 !important;
    min-width: 22px !important;
    min-height: 22px !important;
    max-width: 22px !important;
    max-height: 22px !important;
    border: 2px solid ${(props) => props.theme.color.face.lightBorder};
    background-color: ${(props) => props.theme.color.face.light};

    &:hover {
      background: rgba(114, 57, 149, 0.3);
    }

    &-selected {
      background: ${(props) => props.theme.color.face.contrast};
      width: 24px !important;
      height: 24px !important;
      border-radius: 0 !important;
      top: -1px;
      left: -1px;
    }
  }
`

const CCheckboxField = ({
  field,
  onChange,
  value,
  label,
  dayName,
  ...props
}) => {
  // const [field] = useField(props)

  return (
    <StyledCheckboxField className="mb-5 d-flex align-items-center">
      <Checkbox
        {...props}
        isChecked={value === true || value === '1'}
        required
        onChange={(val) => {
          onChange({
            name: `input_${field.id}`,
            value: val === true || val === '1' ? '1' : '0',
          })
        }}
      />
      <p className="mb-0 ml-2 font-size-sm">{label}</p>
    </StyledCheckboxField>
  )
}

const StyledConsentField = styled.div``

const StyledConsentLabel = styled.div`
  & a {
    color: ${(props) => props.theme.color.face.contrast};
    font-weight: ${(props) => props.theme.font.weight.l};
    text-decoration: underline;
  }
`

const ConsentLabel = () => {
  return (
    <StyledConsentLabel>
      {`Ja, ik ga akkoord met de `}
      <CustomLink external newPage to="/privacyverklaring">
        privacy voorwaarden
      </CustomLink>
      {` van Clear Mind IT Consultancy.`}
    </StyledConsentLabel>
  )
}

const ConsentField = ({ field, onChange, value }) => (
  <StyledConsentField>
    <CCheckboxField
      field={field}
      value={value}
      onChange={onChange}
      // label="Ja, ik ga akkoord met de privacy voorwaarden van Clear Mind IT Consultancy."
      label={<ConsentLabel />}
      name="consent"
    />
  </StyledConsentField>
)

const HTMLField = ({
  value,
  onChange,
  field: {
    id,
    type,
    label,
    labelPlacement,
    content,
    whiteLabels,
    isRequired,
    cssClass,
  },
}) => (
  <StyledTextField className="form-group">
    <div>
      <p className="pl-3 mb-3 mb-lg-5">
        <em>{content}</em>
      </p>
    </div>
  </StyledTextField>
)

export const FormField = ({ consent, newsletter, field, fields, onChange }) => (
  <>
    {field.type === 'consent' && (
      <ConsentField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        newsletter={newsletter}
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'fileupload' && (
      <FileField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'html' && (
      <HTMLField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'submit' && !newsletter && (
      <SubmitButton consent={consent} newsletter={newsletter} field={field} />
    )}
  </>
)

export default GravityForm
